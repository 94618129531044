import React from "react"
import { Link, graphql } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import TacticalImg from "../components/tacticalImg"
import AffPes from "../components/pes2021cta"

import Layout from "../components/layout"
import SEO from "../components/seo"

const GameplayTips = ({ data }) => {
  const tips = data.allTip.edges
  const passingTips = tips
    .filter(entry => entry.node.tag === "Passing")
    .sort(function(a, b) {
      return a.node.title.length - b.node.title.length
    })
  const dribblingTips = tips
    .filter(entry => entry.node.tag === "Dribbling")
    .sort(function(a, b) {
      return a.node.title.length - b.node.title.length
    })
  const goalKeepingTips = tips
    .filter(entry => entry.node.tag === "GoalKeeping")
    .sort(function(a, b) {
      return a.node.title.length - b.node.title.length
    })
  const shootingTips = tips
    .filter(entry => entry.node.tag === "Shooting")
    .sort(function(a, b) {
      return a.node.title.length - b.node.title.length
    })
  const attackingTips = tips
    .filter(entry => entry.node.tag === "Attacking")
    .sort(function(a, b) {
      return a.node.title.length - b.node.title.length
    })
  const defendingTips = tips
    .filter(entry => entry.node.tag === "Defending")
    .sort(function(a, b) {
      return a.node.title.length - b.node.title.length
    })
  const strategyTips = tips
    .filter(entry => entry.node.tag === "Strategy")
    .sort(function(a, b) {
      return a.node.title.length - b.node.title.length
    })
  return (
    <Layout>
      <SEO
        title="Gameplay Tips"
        description="My collection of gameplay tips for PES 2021, so that you can improve in all aspects of the game: passing, shooting, dribbling, attacking, defending and strategy."
      />
      <div className="container">
        <h1>Gameplay Tips</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Gameplay Tips</li>
        </ul>
        <p>
          In this section you will find a series of quick tips and tricks that
          will improve your game. I have been playing PES for many years, both
          online and offline, so I feel like I can contribute with my own set of
          tips - hopefully, you will learn something new!
        </p>
        <p>
          One of the things I treasure the most about PES is how there's always
          some advanced skill that can take your gameplay further. However, that
          new trick usually comes with a learning curve.
        </p>
        <p>
          For this reason, one thing you should keep in mind is that it may take
          a while to master some of these tips. Some rely on quick execution,
          but others are pretty much a "state of mind".
        </p>
        <p>
          If you find something new here, I suggest you try it for 2 weeks or
          so, and see if it works for you. Don't try to incorporate 5 new things
          in your game every week; you will probably end up frustrated and not
          improve at all.
        </p>
        <p>
          Footage is from PES 2020, but since PES 2021 is simply a Season
          Update, everything will work exactly the same.
        </p>
        <p>
          All commands here will be explained with the current default PS4
          controllers for PES 2021, which you can find below. Xbox should be
          similar. Time for the gameplay tips!
        </p>
        <TacticalImg
          src={`pes2021-default-controller-setting.jpg`}
          alt={`PES 2021 Command Setting set to Standard `}
        />
      </div>
      <AffPes />
      <nav id="toc">
        <div className="container">
          <span>Table of Contents</span>
          <ul>
            <li>
              <AnchorLink href="#passingTips" offset="70">
                Passing Tips
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#dribblingTips" offset="70">
                Dribbling Tips
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#shootingTips" offset="70">
                Shooting Tips
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#attackingTips" offset="70">
                Attacking Tips
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#defendingTips" offset="70">
                Defending Tips
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#strategyTips" offset="70">
                Strategy Tips
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#goalkeepingTips" offset="70">
                Goalkeeping Tips
              </AnchorLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container">
        <h2 id="passingTips">Passing Tips</h2>
        <p>
          Passing is essential in PES, just like in real football. The game
          provides you many different options to successfully pass the ball
          between your players, but some I rarely see other players use.
        </p>
        <p>
          Passing precision is very important. If you are good at keeping the
          ball, you can create some nice scoring chances with a proper build up
          play. At the same time, a bad pass can punish you by setting up a
          deadly counter attack.
        </p>
        <p>Here is some advice so that you improve your PES passing game.</p>
        <ul>
          {passingTips.map(tip => (
            <li key={tip.node.id}>
              <Link to={`/gameplay-tips/${tip.node.url}/`}>
                {tip.node.title}
              </Link>
            </li>
          ))}
        </ul>
        <h2 id="dribblingTips">Dribbling Tips</h2>
        <p>
          Compared to other football games, PES is notoriously harder to dribble
          (especially this year) and even the new "Finesse Dribbling" has been
          heavily criticized by pretty much everyone (myself included).
        </p>
        <p>
          You may not be able to dribble from one box to the other with drag
          backs or la croquetas, but there are still some effective ways to get
          past the opposition and even add some flair to your gameplay.
        </p>
        <p>
          Here are some tips that should take your dribbling to the next level.
          The game is much more responsive when playing offline, so keep that in
          mind when in online play.
        </p>
        <ul>
          {dribblingTips.map(tip => (
            <li key={tip.node.id}>
              <Link to={`/gameplay-tips/${tip.node.url}/`}>
                {tip.node.title}
              </Link>
            </li>
          ))}
        </ul>
        <h2 id="shootingTips">Shooting Tips</h2>
        <p>Scoring chances - do you prefer quantity or quality?</p>
        <p>
          In PES, I choose quality over quantity every time. I only strike if I
          see a clear chance of successfully converting my attempt into goal.
        </p>
        <p>
          Some people shoot as much as they can, but sometimes all they get is a
          nice statistic to have a look at when the game ends. I care about
          conversion rate.
        </p>
        <p>
          In this section, I will share with you some ideas to improve your
          efficiency when shooting.
        </p>
        <ul>
          {shootingTips.map(tip => (
            <li key={tip.node.id}>
              <Link to={`/gameplay-tips/${tip.node.url}/`}>
                {tip.node.title}
              </Link>
            </li>
          ))}
        </ul>
        <h2 id="attackingTips">Attacking Tips</h2>
        <p>
          All major tips regarding offensive play will be listed here. This is a
          collection of things you should keep in mind whenever you're in
          possession of the ball and wish to increase your chances of scoring.
        </p>
        <p>
          This is sometimes more a question of having the right mentality while
          attacking, and taking your time to finish a perfect scoring chance.
        </p>
        <ul>
          {attackingTips.map(tip => (
            <li key={tip.node.id}>
              <Link to={`/gameplay-tips/${tip.node.url}/`}>
                {tip.node.title}
              </Link>
            </li>
          ))}
        </ul>
        <h2 id="defendingTips">Defending Tips</h2>
        <p>
          I consider myself a defensive player and I take great pride in keeping
          many clean sheets.
        </p>
        <p>
          Being a defensive player does not mean you have to park the bus - it's
          all about reading the opponent's moves, cutting passing lanes and
          forcing him to perform specific actions.
        </p>
        <p>
          Defending well is as important as converting your goal-scoring
          chances. Here are some tips that should also help you improve when
          you're out of possession.
        </p>
        <ul>
          {defendingTips.map(tip => (
            <li key={tip.node.id}>
              <Link to={`/gameplay-tips/${tip.node.url}/`}>
                {tip.node.title}
              </Link>
            </li>
          ))}
        </ul>
        <h2 id="strategyTips">Strategy Tips</h2>
        <p>
          In PES, it is very important to know your players well - their{" "}
          <Link to="/player-attributes/">attributes</Link>,{" "}
          <Link to="/player-skills/">skills</Link>, and{" "}
          <Link to="/playing-styles/">playing styles</Link> make them unique,
          and some will fit the way you play better than the others.
        </p>
        <p>
          Choosing the right strategy is not just about finding a formation that
          suits you, or the perfect Manager. Regardless of how well you play
          after the referee blows the whistle, you will still need to learn how
          to adapt to the unique circumstances of each game.
        </p>
        <p>
          Here are some tips that may help you get some leverage even before the
          game starts:
        </p>
        <ul>
          {strategyTips.map(tip => (
            <li key={tip.node.id}>
              <Link to={`/gameplay-tips/${tip.node.url}/`}>
                {tip.node.title}
              </Link>
            </li>
          ))}
        </ul>
        <h2 id="goalkeepingTips">GoalKeeping Tips</h2>
        <p>
          Your Goalkeeper can be your last hope in preventing the opposing team
          from scoring or the man that initiates your next offensive move. It is
          not just a dummy controlled by the AI :)
        </p>
        <p>
          Here are some tricks to get the most out of your glove-wearing
          specialist.
        </p>
        <ul>
          {goalKeepingTips.map(tip => (
            <li key={tip.node.id}>
              <Link to={`/gameplay-tips/${tip.node.url}/`}>
                {tip.node.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allTip {
      edges {
        node {
          id
          url
          title
          tag
          videoUrl
          description
        }
      }
    }
  }
`

export default GameplayTips
